body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* .fleet {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
} */

/* .fleet-navigation {
    width: 8rem;
} */

/* .fleet-navigation > * {
    padding: 0.4rem 1rem 0 1rem;
} */

/* .fleet-content {
    flex: 1;
    overflow: scroll;
} */

.permissions {
    display: flex;
    flex-direction: column;
}

.permissions .list {
    margin-top: 0.5rem;
    height: 1px;
}

.card > .form {
    flex: 1;
    overflow-y: scroll;
}

/* .card {
    overflow: scroll;
} */

.form-input {
    width: 49%;
}

.form-input > h3 {
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form-input > input,
.form-input > select {
    width: 100%;
}

.dropdown-dark {
    background-color: rgb(49, 49, 49) !important;
}

.dropdown {
    width: 100%;
    background-color: white;
    position: absolute;
    z-index: 1;
    max-height: 200px;
    overflow: scroll;
    border: 1px solid var(--chakra-ring-color);
    border-radius: 4px;
}

.dropdown li {
    padding-left: 0.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown li:hover {
    background-color: rgba(100, 148, 237, 0.341);
    cursor: pointer;
}

.sand-form {
    display: flex;
    flex-wrap: wrap;
}

.sand-form > div {
    width: 50%;
    padding: 0.2rem;
}

/* LIBRARY ELEMENTS STYLING */
.react-datetime-picker,
.react-datetime-picker__wrapper {
    width: 100% !important;
}

.datetime-picker-dark.react-datetime-picker .react-calendar {
    background-color: rgb(25, 29, 43) !important;
}

.datetime-picker-dark.react-datetime-picker .react-calendar__tile--now {
    color: black;
}

.datetime-picker-dark.react-datetime-picker--enabled .react-datetime-picker__wrapper {
    border-color: #f0f0f0 !important;
}

.datetime-picker-light.react-datetime-picker--enabled .react-datetime-picker__wrapper {
    border-color: #bbbbbb !important;
}

.datetime-picker-dark.react-datetime-picker--disabled .react-datetime-picker__wrapper {
    border-color: #f0f0f04b !important;
}

.datetime-picker-light.react-datetime-picker--disabled .react-datetime-picker__wrapper {
    border-color: #bbbbbb4b !important;
}

.react-datetime-picker__wrapper {
    height: var(--chakra-sizes-10);
    border-radius: var(--chakra-radii-md);
    border: 1px solid;
    font-size: var(--chakra-fontSizes-md) !important;
    font-family: var(--chakra-fonts-body) !important;
    padding-left: 14px;
}

.react-datetime-picker--disabled {
    background-color: transparent !important;
}

.react-datetime-picker__button {
    padding: 0 !important;
    opacity: 0.6;
}

/* .css-table */
.css-table {
    width: 100%;
    height: 20rem;
    border-collapse: collapse;
}

.description {
    margin: 1rem 0;
}

.css-table,
.css-table td,
.css-table th {
    border: 1px solid #121212;
}

.css-table td {
    text-align: center;
    vertical-align: middle;
}

.css-table th {
    background-color: #eaf3f8;
}

.css-table-dark th {
    background-color: #222a2e;
}

.current {
    background-color: #daa283;
    color: white;
}

.routine-headers th {
    font-size: 0.8rem;
    font-weight: 600;
}

.chakra-form__label {
    font-size: 0.8rem !important;
    margin-bottom: 0rem !important;
}

/* removed watermarks */
.highcharts-credits {
    display: none;
}

.ag-watermark {
    display: none !important;
}

/* ag-grid styling */
.ag-grid-roles .ag-react-container {
    height: 100%;
}

.ag-grid-roles .ag-header-cell {
    height: 100%;
    padding: 0 !important;
}

.ag-grid-roles .ag-header-cell-label {
    display: flex;
    justify-content: center;
}

.ag-grid-roles .ag-cell {
    text-align: center;
}

/* This is used to make the InfoPopup elements be above the modals. An example would be HumidityThresholdsPopup and TemperatureThresholdsPopup in device-simulation.jsx modal. */
.chakra-popover__popper {
    z-index: 100000 !important;
}